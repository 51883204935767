import { ChatResponse } from '../models/chat.model.ts';
import axios from 'axios';
import { ResponseType } from '../interceptors/interceptors.ts';

export const askAqarxAI = async ({
	message,
	convoId = undefined,
}: {
	message: string;
	convoId?: string;
}): Promise<ChatResponse> => {
	const res = await axios.post<ResponseType<ChatResponse>>(
		'api/AIAssistant/AskAqarxAI',
		{
			message,
			convo_id: convoId
		},
	);
	return res.data.data as ChatResponse;
};
