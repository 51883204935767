import { atomWithReducer } from 'jotai/utils';
import { GetPropertyListDto } from '../models/property.model.ts';
import { PropertyFilters } from '../models/property-filters.model.ts';

export enum FilterActionsType {
	NEXT_PAGE = 'NEXT_PAGE',
	REFRESH = 'REFRESH',
	FILTER_CHANGE = 'FILTER_CHANGE',
	SORT_CHANGE = 'SORT_CHANGE',
	RESET_FILTERS = 'RESET_FILTERS',
}

const propertyFilterReducer = (
	state: GetPropertyListDto,
	action: {
		type: FilterActionsType;
		payload?: typeof state.filters | typeof state.sort;
	},
): GetPropertyListDto => {
	switch (action.type) {
		case FilterActionsType.NEXT_PAGE:
			return { ...state, page: state.page + 1 };
		case FilterActionsType.REFRESH:
			return { ...state, page: 1 };
		case FilterActionsType.RESET_FILTERS:
			return { page: 1 } as GetPropertyListDto;
		case FilterActionsType.FILTER_CHANGE:
			return {
				...state,
				page: 1,
				filters: {
					...state.filters,
					...action.payload,
					text: (action.payload as PropertyFilters)?.text || undefined,
				},
			} as GetPropertyListDto;
		case FilterActionsType.SORT_CHANGE:
			return { ...state, page: 1, sort: action.payload } as GetPropertyListDto;
		default:
			return state;
	}
};

export const propertyFilterAtom = atomWithReducer(
	{ page: 1 },
	propertyFilterReducer,
);
