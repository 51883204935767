import { memo } from 'react';

const Rate = memo(function (props: { rating: number }) {
	const { rating } = props;

	const fullStars = Math.floor(rating);
	const hasHalfStar = rating % 1 >= 0.5;

	const renderStars = () => {
		const stars = [];
		for (let i = 1; i <= 5; i++) {
			if (i <= fullStars) {
				stars.push(<i key={i} className='stars icon-star-filled' />);
			} else if (i === fullStars + 1 && hasHalfStar) {
				stars.push(<i key={i} className='stars icon-2filled' />);
			} else {
				stars.push(<i key={i} className='stars icon-star' />);
			}
		}
		return stars;
	};

	return <div className='rating'>{renderStars()}</div>;
});

export default Rate;