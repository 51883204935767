import { forwardRef, memo, ReactElement, Ref } from 'react';
import DialogProps from './dialog.props.ts';
import { Dialog as MatDialog, Slide,  } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function (
	props: TransitionProps & {
		children: ReactElement<any, any>;
	},
	ref: Ref<unknown>,
) {
	return (
		<Slide direction='up' ref={ref} {...props} children={props.children} />
	);
});

const Dialog = memo(function (props: DialogProps) {
	return (
		<MatDialog {...props} TransitionComponent={Transition}>
			{props.children}
		</MatDialog>
	);
});

export default Dialog;
