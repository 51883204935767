import { memo } from 'react';
import { SwipeableDrawer } from '@mui/material';
import ActionSheetProps from './action-sheet.props.ts';

const ActionSheet = memo(function (props: ActionSheetProps) {
	const container = () => window.document.body;

	return (
		<SwipeableDrawer
			onClose={props.onClose || (() => {})}
			onOpen={props.onOpen || (() => {})}
			open={props.open}
			anchor='bottom'
			container={container}>
			<div className='action-sheet'>
				<div className='action-sheet__handle'></div>
				{props.children}
			</div>
		</SwipeableDrawer>
	);
});

export default ActionSheet;
