import { memo, useEffect, useState } from 'react';
import { Slider, styled } from '@mui/material';
import { UnitFilterProps } from './unit-filter.props.ts';
import {
	FilterActionsType,
	propertyFilterAtom,
} from '../../atoms/properties-filter.atom.ts';
import { useAtom } from 'jotai';
import { propertiesFilterValuesAtom } from '../../atoms/properties-filter-values.atom.ts';

const CustomSlider = styled(Slider)(() => ({
	color: '#4545E8',
	'& .MuiSlider-thumb': {
		backgroundColor: '#fff',
		border: '1px solid #4545E8',
		width: 30,
		height: 30,
	},
	'& .MuiSlider-rail': {
		height: 8,
	},
	'& .MuiSlider-track': {
		height: 8,
	},
}));

const UnitFilter = memo(function (props: UnitFilterProps) {
	const [filterValues] = useAtom(propertiesFilterValuesAtom);

	const [unitPrice, setUnitPrice] = useState<number[]>([100000, 5000000]);
	const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
	const [selectedBathrooms, setSelectedBathrooms] = useState<number[]>([]);
	const [selectedLivingRooms, setSelectedLivingRooms] = useState<number[]>([]);
	const [selectedHalls, setSelectedHalls] = useState<number[]>([]);
	const [filter, dispatchFilter] = useAtom(propertyFilterAtom);

	useEffect(() => {
		if (!filter.filters) {
			setUnitPrice([100000, 5000000]);
			setSelectedRooms([]);
			setSelectedBathrooms([]);
			setSelectedLivingRooms([]);
			setSelectedHalls([]);
		}
	}, [filter]);

	const setBedroomsFilter = (num: number) => {
		if (selectedRooms?.includes(num))
			setSelectedRooms(selectedRooms?.filter(item => item !== num));
		else setSelectedRooms([...selectedRooms, num]);
	};

	const setBathroomsFilter = (num: number) => {
		if (selectedBathrooms?.includes(num))
			setSelectedBathrooms(selectedBathrooms?.filter(item => item !== num));
		else setSelectedBathrooms([...selectedBathrooms, num]);
	};

	const setLivingRoomsFilter = (num: number) => {
		if (selectedLivingRooms?.includes(num))
			setSelectedLivingRooms(selectedLivingRooms?.filter(item => item !== num));
		else setSelectedLivingRooms([...selectedLivingRooms, num]);
	};

	const setHallsFilter = (num: number) => {
		if (selectedHalls?.includes(num))
			setSelectedHalls(selectedHalls?.filter(item => item !== num));
		else setSelectedHalls([...selectedHalls, num]);
	};

	const submitFilter = () => {
		dispatchFilter({
			type: FilterActionsType.FILTER_CHANGE,
			payload: {
				price: { min: unitPrice[0], max: unitPrice[1] },
				rooms: selectedRooms,
				bathrooms: selectedBathrooms,
				livingrooms: selectedLivingRooms,
				guest_rooms: selectedHalls,
			},
		});
		props.onCancel && props.onCancel();
	};

	if (filterValues && filterValues.filters) {
		const { price, rooms, bathrooms } = filterValues!.filters;

		return (
			<div className='unit-filter'>
				<div className='unit-filter__items gap-13'>
					<div className='unit-filter__item gap-13'>
						<h4 className='m-0 text-start text-secondary'>سعر الوحدة</h4>
						<div className='d-flex gap-10'>
							<div className='d-flex flex-column justify-content-start'>
								<label className='text-start mb-8 fw-500 text-secondary'>
									أقل سعر
								</label>
								<div className='input-container full-width'>
									<input
										className='full-width no-border no-outline'
										value={`${(price?.max || 0) - unitPrice[1] + (price?.min || 0)} ريال`}
									/>
								</div>
							</div>
							<div className='d-flex flex-column justify-content-start'>
								<label className='text-start mb-8 fw-500 text-secondary'>
									أكثر سعر
								</label>
								<div className='input-container full-width'>
									<input
										className='full-width no-border no-outline'
										value={`${(price?.max || 0) - unitPrice[0] + (price?.min || 0)} ريال`}
									/>
								</div>
							</div>
						</div>
						<div className='px-25'>
							<CustomSlider
								min={price?.min}
								max={price?.max}
								value={unitPrice}
								onChange={(_, value) => setUnitPrice(value as number[])}
							/>
						</div>
					</div>
					<div className='unit-filter__item gap-13'>
						<h4 className='m-0 text-start text-secondary'>عدد الغرف</h4>
						<div className='d-flex flex-wrap gap-13'>
							{rooms?.map(num => (
								<button
									key={num}
									className={`unit-filter__chip ${selectedRooms.includes(num) && 'selected'}`}
									onClick={() => setBedroomsFilter(num)}>
									<i className='icon-single-bed'></i>
									{num} غرفة
								</button>
							))}
						</div>
					</div>
					<div className='unit-filter__item gap-13'>
						<h4 className='m-0 text-start text-secondary'>عدد الحمامات</h4>
						<div className='d-flex flex-wrap gap-13'>
							{bathrooms?.map(num => (
								<button
									key={num}
									className={`unit-filter__chip ${selectedBathrooms.includes(num) && 'selected'}`}
									onClick={() => setBathroomsFilter(num)}>
									<i className='icon-shower-head'></i>
									{num} {num > 2 ? 'حمامات' : 'حمام'}
								</button>
							))}
						</div>
					</div>
					<div className='unit-filter__item gap-13'>
						<h4 className='m-0 text-start text-secondary'>عدد المجلس</h4>
						<div className='d-flex flex-wrap gap-13'>
							{[2, 3].map(num => (
								<button
									key={num}
									className={`unit-filter__chip ${selectedLivingRooms.includes(num) && 'selected'}`}
									onClick={() => setLivingRoomsFilter(num)}>
									<i className='icon-graphic-stitching-four'></i>
									{num} مجلس
								</button>
							))}
						</div>
					</div>
					<div className='unit-filter__item gap-13'>
						<h4 className='m-0 text-start text-secondary'>عدد الصالة</h4>
						<div className='d-flex flex-wrap gap-13'>
							{[1, 2].map(num => (
								<button
									key={num}
									className={`unit-filter__chip ${selectedHalls.includes(num) && 'selected'}`}
									onClick={() => setHallsFilter(num)}>
									<i className='icon-group'></i>
									{num} صالة
								</button>
							))}
						</div>
					</div>
				</div>
				<button className='apply-btn fs-14 text-bold mt-10' onClick={submitFilter}>
					إختيار
				</button>
				<button className='cancel-btn fs-14 text-bold' onClick={props.onCancel}>
					إلغاء
				</button>
			</div>
		);
	} else {
		return null;
	}
});

export default UnitFilter;
