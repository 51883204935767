import _ from 'lodash';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import {
	Checkbox,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { propertiesFilterValuesAtom } from '../../atoms/properties-filter-values.atom.ts';
import { useAtom } from 'jotai';
import { UnitLocationProps } from './unit-location.props.ts';
import {
	FilterActionsType,
	propertyFilterAtom,
} from '../../atoms/properties-filter.atom.ts';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const UnitLocation = memo(function (props: UnitLocationProps) {
	const [filterValues] = useAtom(propertiesFilterValuesAtom);
	const [filter, filterDispatcher] = useAtom(propertyFilterAtom);
	const [cityCodes, setCityCodes] = useState<string[]>([]);
	const [districtsCodes, setDistrictsCodes] = useState<string[]>([]);
	const [cityExpansion, setCityExpansion] = useState<boolean[]>([]);
	const [searchText, setSearchText] = useState(undefined);
	const searchTextRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (
			!filter.filters ||
			(filter.filters && (!filter.filters.cities || !filter.filters.districts))
		) {
			setCityCodes([]);
			setDistrictsCodes([]);
		}
	}, [filter]);

	useEffect(() => {
		if (filterValues?.cities?.length) {
			setCityExpansion(new Array(filterValues?.cities.length));
		}
	}, [filterValues?.cities]);

	useEffect(() => {
		for (const distCode of districtsCodes) {
			const city = filterValues?.cities.find(city =>
				city.districts.some(dist => dist.code === distCode),
			);
			const citySelectedDistricts = districtsCodes.filter(code =>
				city?.districts.some(dist => dist.code === code),
			);
			if (citySelectedDistricts.length === city?.districts.length) {
				setCityCodes(prev => [...new Set([...prev, city.code])]);
			} else {
				setCityCodes(prev => prev.filter(code => city?.code !== code));
			}
		}
	}, [districtsCodes]);

	const handleSearch = (e: any) => {
		setSearchText(e.target.value);
	};

	const resetSearch = () => {
		setSearchText(undefined);
		if (searchTextRef.current) {
			searchTextRef.current!.value = '';
		}
	};

	const handleChangeCities = (cityCode: string) => {
		const exist = cityCodes.some(code => code === cityCode);
		if (!exist) {
			setCityCodes(prev => {
				return [...prev, cityCode];
			});
		} else {
			setCityCodes(prev => {
				return prev.filter(code => code !== cityCode);
			});
		}
		const city = filterValues?.cities.find(city => city.code === cityCode);
		setDistrictsCodes(prev => {
			if (exist) {
				return prev.filter(
					code => !city?.districts.some(dist => dist.code === code),
				);
			} else {
				return [
					...new Set([...prev, ...city!.districts.map(dist => dist.code)]),
				];
			}
		});
	};

	const handleChangeDistricts = (districtCode: string) => {
		const exist = districtsCodes.some(code => code === districtCode);
		if (!exist) {
			setDistrictsCodes(prev => {
				return [...prev, districtCode];
			});
		} else {
			setDistrictsCodes(prev => {
				return prev.filter(code => code !== districtCode);
			});
		}
	};

	const isCityDeterminate = (index: number) => {
		const city = filterValues?.cities[index];
		if (cityCodes.includes(city!.code)) return false;
		const citySelectedDistricts = districtsCodes.filter(code =>
			city?.districts.some(dist => dist.code === code),
		);
		return (
			citySelectedDistricts.length > 0 &&
			citySelectedDistricts.length !== city?.districts.length
		);
	};

	const toggleCityExpansion = (index: number) => {
		setCityExpansion(prev => {
			const expansions = [...prev];
			expansions[index] = !expansions[index];
			return expansions;
		});
	};

	const apply = () => {
		if (cityCodes.length || districtsCodes.length) {
			filterDispatcher({
				type: FilterActionsType.FILTER_CHANGE,
				payload: { cities: cityCodes, districts: districtsCodes },
			});
		}
		props.onCancel && props.onCancel();
	};

	if (filterValues) {
		const { cities } = filterValues;

		return (
			<div className='unit-location d-felx full-width p-8'>
				<div className=''>
					<h4 className='m-0 text-start text-secondary fs-20'>الموقع</h4>
					<p className='d-flex fs-15 m-0 mb-20 text-grey-400 justify-content-start'>
						قم بتخصيص بحثك بكل سهولة
					</p>
				</div>
				<div className='search-bar'>
					<i className='icon-search text-grey-200 me-10'></i>
					<input
						ref={searchTextRef}
						type='text'
						className='search-bar__input text-primary'
						placeholder='ابحث هنا'
						onKeyUp={handleSearch}
					/>
					{searchText && (
						<button className='btn-link' onClick={resetSearch}>
							<i className='icon-close text-primary'></i>
						</button>
					)}
				</div>
				<List
					className='unit-location__items full-width mb-30'
					disablePadding
					component='div'>
					{cities
						?.filter(
							city =>
								city.name.includes(searchText || '') ||
								city.districts.some(dist =>
									dist.name.includes(searchText || ''),
								),
						)
						.map((city, cityIndex) => (
							<Fragment key={cityIndex}>
								<ListItem
									component='div'
									key={cityIndex}
									disablePadding
									secondaryAction={
										<IconButton onClick={() => toggleCityExpansion(cityIndex)}>
											{cityExpansion[cityIndex] ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</IconButton>
									}>
									<ListItemButton onClick={() => handleChangeCities(city.code)}>
										<ListItemIcon>
											<Checkbox
												indeterminate={isCityDeterminate(cityIndex)}
												checked={cityCodes?.includes(city.code)}
												edge='start'
												value={city.code}
											/>
										</ListItemIcon>
										<ListItemText primary={city.name}></ListItemText>
									</ListItemButton>
								</ListItem>
								<Collapse
									in={cityExpansion[cityIndex]}
									timeout='auto'
									unmountOnExit>
									<List disablePadding component='div'>
										{_.uniqBy(city.districts, dis => dis.id)
											.filter(district =>
												district.name.includes(searchText || ''),
											)
											.map((district, dIndex) => (
												<ListItemButton
													key={dIndex}
													onClick={() => handleChangeDistricts(district.code)}>
													<ListItemIcon>
														<Checkbox
															checked={districtsCodes?.includes(district.code)}
															edge='start'
															value={district.code}
														/>
													</ListItemIcon>
													<ListItemText primary={district.name}></ListItemText>
												</ListItemButton>
											))}
									</List>
								</Collapse>
							</Fragment>
						))}
				</List>
				<button className='apply-btn fs-14 text-bold mt-10' onClick={apply}>
					إختيار
				</button>
				<button className='cancel-btn fs-14 text-bold' onClick={props.onCancel}>
					إلغاء
				</button>
			</div>
		);
	}
	return null;
});

export default UnitLocation;
