import { Fragment, memo, useEffect, useRef } from 'react';
import Dialog from '../dialog/dialog.tsx';
import { useAtom } from 'jotai/index';
import { DialogContent } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import Slider, { Settings } from 'react-slick';
import Typewriter from 'typewriter-effect';
import { ChatResponse } from '../../models/chat.model.ts';
import { askAqarxAI } from '../../services/chat.service.ts';
import Post from '../post/post.tsx';
import { chatMessagesAtom, chatOpenAtom } from '../../atoms/chat.atom.ts';

const Chat = memo(function () {
	const [chatOpen, setChatOpen] = useAtom(chatOpenAtom);
	const [messages, setMessages] = useAtom(chatMessagesAtom);
	const chatAreaRef = useRef<HTMLDivElement>(null);
	const messageRef = useRef<HTMLInputElement>(null);
	const convoId = useRef<string>();

	const mutation = useMutation<
		ChatResponse,
		any,
		{ message: string; convoId: string | undefined }
	>({
		mutationFn: askAqarxAI,
	});

	const sliderSettings: Settings = {
		dots: false,
		infinite: false,
		slidesToShow: 1.5,
		slidesToScroll: 1,
		centerPadding: '12px',
	};

	useEffect(() => {
		if (mutation.data) {
			const message = mutation.data;
			convoId.current = message.convo_id;
			setMessages([...messages, message]);
			scrollToBottom({ smooth: true });
		}
	}, [mutation.data]);

	useEffect(() => {
		scrollToBottom({ smooth: false });
	});

	const toggleChat = () => {
		setChatOpen(!chatOpen);
	};

	const sendMessage = () => {
		const message = messageRef.current?.value;
		if (message) {
			setMessages([...messages, { message }]);
			mutation.mutate({ message, convoId: convoId.current || undefined });
			messageRef.current.value = '';
			messageRef.current.focus();
			scrollToBottom({ smooth: true });
		}
	};

	const scrollToBottom = ({
		smooth,
		interval,
	}: {
		smooth: boolean;
		interval?: number;
	}) => {
		setTimeout(
			() =>
				chatAreaRef.current?.scrollTo({
					top: chatAreaRef.current?.scrollHeight,
					...(smooth && { behavior: 'smooth' }),
				}),
			interval || 100,
		);
	};

	const handleTypingComplete = (index: number) => {
		const mgs = [...messages];
		mgs[index].doneTyping = true;
		mgs[index].showProperties = true;
		setMessages(mgs);
		// scrollToBottom({ smooth: true, interval: 200 });
	};

	return (
		<>
			<button className='chat-button' onClick={toggleChat}>
				<i className='icon-chat'></i>
			</button>
			<Dialog
				open={chatOpen}
				fullScreen={true}
				onClose={() => setChatOpen(false)}>
				<DialogContent className='p-16' style={{ minWidth: 300 }}>
					<div className='chat d-flex flex-column justify-content-between full-height'>
						<div className='d-flex justify-content-between full-width'>
							<h4 className='text-primary m-0'>عقار شريكك الذكي لمساعدتك؟</h4>
							<button
								className='bg-transparent no-border'
								onClick={toggleChat}>
								<i className='icon-down text-primary fs-28'></i>
							</button>
						</div>
						<div className='chat__chat-area' ref={chatAreaRef}>
							{messages.map((message, index) => {
								if (index % 2 === 0) {
									return (
										<Fragment key={index}>
											<div className='chat__message received'>
												<h5 className='text-secondary chat__message__text'>
													{message.doneTyping ? (
														message.message
													) : (
														<Typewriter
															options={{ autoStart: true, delay: 50 }}
															onInit={typeWriter =>
																typeWriter
																	.typeString(message.message)
																	.start()
																	.pauseFor(Infinity)
																	.callFunction(() =>
																		handleTypingComplete(index),
																	)
															}
														/>
													)}
												</h5>
												<div className='chat__message__circle'>
													<i className='icon-logo'></i>
												</div>
											</div>
											{message.search_results?.properties && (
												<div
													style={{
														visibility: message.showProperties
															? 'visible'
															: 'hidden',
													}}>
													<Slider {...sliderSettings}>
														{message.search_results?.properties?.data.map(
															property => (
																<div key={property.id} className='pe-12'>
																	<Post property={property} small />
																</div>
															),
														)}
													</Slider>
												</div>
											)}
										</Fragment>
									);
								} else {
									return (
										<div className='chat__message sent' key={index}>
											<div className='chat__message__circle'></div>
											<h5 className='text-secondary chat__message__text'>
												{message.message}
											</h5>
										</div>
									);
								}
							})}
						</div>
						<div className='chat__input-container'>
							<button
								className='chat__input-container__button'
								onClick={sendMessage}>
								<i className='icon-send'></i>
							</button>
							<input
								ref={messageRef}
								type='text'
								className='chat__input-container__input'
								placeholder='انقر هنا للكتابة '
								onKeyDown={e => e.key === 'Enter' && sendMessage()}
							/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
});

export default Chat;
