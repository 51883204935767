import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { memo, useContext, useEffect } from 'react';
import { MessageContext } from '../context/message.context.tsx';

export type ResponseType<T = never> = {
	code: number,
	data: T,
	error: string,
	message: string,
};

const ApiInterceptor = memo(function () {
	const { showMessage } = useContext(MessageContext);

	useEffect(() => {
		axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
			if (!config.baseURL) {
				config.baseURL = import.meta.env.VITE_BASE_URL;
			}
			return config;
		});

		axios.interceptors.response.use((response: AxiosResponse<ResponseType>) => {
			if (response?.status === 401) {
				showMessage('غير مسموح', { severity: 'error' });
			}
			if (response?.status === 404) {
				showMessage('غير موجود', { severity: 'error' });
			}
			if (response?.status === 500) {
				showMessage('حدث خطا بالسيرفر', { severity: 'error' });
			}
			if (response?.data.code === 422) {
				let message = '';
				const props = response.data.data as Record<string, any>;
				Object.keys(props).forEach((key: string) => {
					message += `${key}: ${props[key][0]}\n`;
				});
				showMessage(message, { severity: 'error' });
			}
			return response;
		}, () => {
			showMessage('حدث خطا بالسيرفر', { severity: 'error' });
		});
	}, []);

	return null;
});

export default ApiInterceptor;
