import { memo, useRef, useState } from 'react';
import { Popover as MatPopover } from '@mui/material';
import { PopoverProps } from './popover.props.ts';

const Popover = memo(function (props: PopoverProps) {
	const [open, setOpen] = useState<boolean>(false);
	const anchorEL = useRef<HTMLElement | null>(null);

	return (
		<>
			<span onClick={() => setOpen(true)} ref={anchorEL}>
				{props.children}
			</span>
			<MatPopover
				open={open}
				onClose={() => setOpen(false)}
				anchorEl={anchorEL.current}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				<div className='d-flex p-16'>
					<p className='m-0 text-secondary'>{props.text}</p>
					<div>
						<button
							className='no-border bg-transparent fs-18'
							onClick={() => setOpen(false)}>
							<i className='icon-close text-primary'></i>
						</button>
					</div>
				</div>
			</MatPopover>
		</>
	);
});

export default Popover;
