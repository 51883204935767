import { memo, useEffect, useState } from 'react';
import {
	AdvancedMarker,
	Map as GMap,
	MapProps,
} from '@vis.gl/react-google-maps';
import { useLocation } from 'react-router-dom';

const Map = memo(function (props: { lat: number; lng: number }) {
	const { lat, lng } = props;

	const location = useLocation();

	const [currentLocation, setCurrentLocation] = useState<{
		lat: number;
		lng: number;
	} | null>(null);

	const [moveToCurrent, setMoveToCurrent] = useState(false);

	useEffect(() => {
		let timer: number | null = null;

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				function (position) {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};

					setCurrentLocation({ lat: pos.lat, lng: pos.lng });
				},
				error => alert('Error: ' + error.message),
			);
		} else {
			alert('Browser does not support geolocation');
		}

		const updateButtonLabels = () => {
			const satelliteButton = document.querySelector(
				'button[aria-label*="satellite"]',
			);
			const mapButton = document.querySelector('button[aria-label*=street]');

			if (satelliteButton && mapButton) {
				satelliteButton.innerHTML = 'قمر صناعي';
				mapButton.innerHTML = 'خريطة';
				return true;
			}
			return false;
		};

		const checkButtons = () => {
			if (!updateButtonLabels()) {
				timer = requestAnimationFrame(checkButtons);
			}
		};

		checkButtons();

		return () => {
			if (timer) {
				cancelAnimationFrame(timer);
			}
		};
	}, [location]);

	const mapOptions: MapProps = {
		mapTypeId: google.maps.MapTypeId?.SATELLITE,
		gestureHandling: 'greedy',
		style: { height: 280 },
		mapId: 'Property',
		defaultZoom: 19,
		defaultCenter: { lat, lng },

		streetViewControl: false,
		...(moveToCurrent && currentLocation?.lat && currentLocation?.lng
			? {
					center: { lat: currentLocation.lat, lng: currentLocation.lng },
					zoom: 19,
				}
			: {}),
		onZoomChanged: () => setMoveToCurrent(false),
		onCenterChanged: () => setMoveToCurrent(false),
	};

	return (
		<div className='map'>
			<GMap {...mapOptions}>
				<AdvancedMarker position={{ lat, lng }} />
				{currentLocation && (
					<AdvancedMarker position={currentLocation}>
						<div
							style={{
								width: 18,
								height: 18,
								position: 'absolute',
								top: 0,
								left: 0,
								background: '#4285F4',
								border: '2px solid #fff',
								boxShadow: '1px 1px 4px',
								borderRadius: '50%',
								transform: 'translate(-50%, -50%)',
							}}></div>
					</AdvancedMarker>
				)}
			</GMap>
			<button
				className='current-location-btn fs-20 text-grey'
				onClick={() => setMoveToCurrent(true)}>
				<i className='icon-current-location'></i>
			</button>
		</div>
	);
});

export default Map;
