import { memo, useState } from 'react';
import { UnitSortProps } from './unit-sort.props.ts';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAtom } from 'jotai/index';
import { FilterActionsType, propertyFilterAtom } from '../../atoms/properties-filter.atom.ts';

const UnitSort = memo(function (props: UnitSortProps) {
	const [sort, setSort] = useState<'nearest' | 'cheapest' | 'most-expensive'>();
	const filterDispatcher = useAtom(propertyFilterAtom)[1];

	const handleChangeSort = (e: any) => {
		setSort(e.target.value);
	};

	const applySort = () => {
		if (sort) {
			switch (sort) {
				case 'nearest':
					filterDispatcher({
						type: FilterActionsType.SORT_CHANGE,
						payload: { factor: 'created_at', order: 'asc' },
					});
					break;
				case 'cheapest':
					filterDispatcher({
						type: FilterActionsType.SORT_CHANGE,
						payload: { factor: 'price', order: 'asc' },
					});
					break;
				case 'most-expensive':
					filterDispatcher({
						type: FilterActionsType.SORT_CHANGE,
						payload: { factor: 'price', order: 'desc' },
					});
					break;
			}
		}
		props.onCancel && props.onCancel();
	};

	return (
		<div className='unit-sort d-felx full-width p-8'>
			<div className=''>
				<h4 className='m-0 text-start text-secondary fs-20'>الترتيب</h4>
				<p className='d-flex fs-15 m-0 mb-20 text-grey-400 justify-content-start'>
					قم بتخصيص بحثك بكل سهولة
				</p>
			</div>
			<div className='unit-sort__items full-width mb-30'>
				<RadioGroup name='unit_location' onChange={handleChangeSort}>
					<FormControlLabel
						value='nearest'
						label='الترتيب حسب الأقرب'
						control={<Radio />}
					/>
					<hr className='my-5 full-width' />
					<FormControlLabel
						value='cheapest'
						label='الترتيب حسب الأقل سعراً'
						control={<Radio />}
					/>
					<hr className='my-5 full-width' />
					<FormControlLabel
						value='most-expensive'
						label='الترتيب حسب الأكثر سعراً'
						control={<Radio />}
					/>
				</RadioGroup>
			</div>
			<button className='apply-btn fs-14 text-bold' onClick={applySort}>إختيار</button>
			<button className='cancel-btn fs-14 text-bold' onClick={props.onCancel}>
				إلغاء
			</button>
		</div>
	);
});

export default UnitSort;
