import { Fragment, memo, useState } from 'react';
import { UnitTypeProps } from './unit-type.props.ts';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAtom } from 'jotai/index';
import {
	FilterActionsType,
	propertyFilterAtom,
} from '../../atoms/properties-filter.atom.ts';
import { propertiesFilterValuesAtom } from '../../atoms/properties-filter-values.atom.ts';
import { PropType } from '../../models/property-filters.model.ts';

const UnitType = memo(function (props: UnitTypeProps) {
	const [filterValues] = useAtom(propertiesFilterValuesAtom);
	const filterDispatcher = useAtom(propertyFilterAtom)[1];
	const [unitTypes, setUnitTypes] = useState<PropType[]>([]);

	const handleChangeTypes = (e: any) => {
		if (e.target.checked) {
			setUnitTypes(prev => {
				return [...prev, e.target.value];
			});
		} else {
			setUnitTypes(prev => {
				return prev.filter(code => code !== e.target.value);
			});
		}
	};

	const apply = () => {
		if (unitTypes.length) {
			filterDispatcher({
				type: FilterActionsType.FILTER_CHANGE,
				payload: { property_types: unitTypes },
			});
		}
		props.onCancel && props.onCancel();
	};

	if (filterValues && filterValues.filters) {
		const { property_types } = filterValues.filters;

		return (
			<div className='unit-sort d-felx full-width p-8'>
				<div className=''>
					<h4 className='m-0 text-start text-secondary fs-20'>نوع العقار</h4>
					<p className='d-flex fs-15 m-0 mb-20 text-grey-400 justify-content-start'>
						قم بتخصيص بحثك بكل سهولة
					</p>
				</div>
				<div className='unit-sort__items full-width mb-30'>
					<hr className='my-5 full-width' />
					{property_types?.map((type, index) => (
						<Fragment key={index}>
							<FormControlLabel
								value={type.code}
								label={type.type}
								control={<Checkbox onChange={handleChangeTypes} />}
							/>
							<hr className='my-5 full-width' />
						</Fragment>
					))}
				</div>
				<button className='apply-btn fs-14 text-bold' onClick={apply}>
					إختيار
				</button>
				<button className='cancel-btn fs-14 text-bold' onClick={props.onCancel}>
					إلغاء
				</button>
			</div>
		);
	}
	return null;
});

export default UnitType;
