import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Property } from '../../models/property.model.ts';

const Post = memo(function (props: { property: Property; small?: boolean }) {
	const { property, small } = props;

	return (
		<Link
			to={`/details/${property.id}`}
			state={property}
			className='text-decoration-none'>
			<div className={`post ${small ? 'small' : null}`}>
				<img
					src={property.images[0]}
					className='object-fit-cover full-width post__image'
					alt='post-image'
				/>
				<h3 className={`text-primary  ${small ? 'm-6 fs-9' : 'm-12 fs-18'}`}>
					{property.title}
				</h3>
				<h5 className={`text-grey-300 mx-12 ${small ? 'fs-7' : 'fs-13'} my-0`}>
					{property.description}
				</h5>
				<div className='post__features mx-12 mt-12 mb-5'>
					<div className='post__features__feature'>
						<i className='icon-overall-reduction me-4'></i>
						{property.size} م2
					</div>
					<div className='post__features__feature'>
						<i className='icon-single-bed me-4'></i>
						{property.rooms} غرف
					</div>
					<div className='post__features__feature'>
						<i className='icon-shower-head me-4'></i>
						{property.bathrooms} دورات مياه
					</div>
				</div>
				<div className='post__values mx-12'>
					<div className='post__values__prices'>
						<h4 className='text-secondary'>
							{property.discount
								? property.price - property.price * property.discount
								: property.price}
						</h4>
						{property.discount !== 0 && (
							<h4 className='text-red line-through'>{property.price}</h4>
						)}
						<p className={`text-secondary ${small ? 'fs-6' : 'fs-12'}`}>ريال</p>
					</div>
					<div className='post__values__impressions'>
						<p className='post__values__impressions__watches'>
							{property.views} <i className='icon-preview-open'></i>
						</p>
						<p className='post__values__impressions__rate'>
							{property.ratings
								.reduce((avg, value, _, { length }) => {
									return avg + value.rating / length;
								}, 0)
								.toFixed(1)}
							<i className='icon-star'></i>
						</p>
					</div>
				</div>
			</div>
		</Link>
	);
});

export default Post;
