import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Property } from '../models/property.model.ts';
import _ from 'lodash';

export enum PropertiesActionTypes {
	ADD_PROPERTIES = 'ADD_PROPERTIES',
	REFRESH_PROPERTIES = 'REFRESH_PROPERTIES',
}

interface PropertiesAction {
	type:
		| PropertiesActionTypes.ADD_PROPERTIES
		| PropertiesActionTypes.REFRESH_PROPERTIES;
	props: Property[];
}

export const propertiesReducer = (
	state: Property[],
	action: PropertiesAction,
): Property[] => {
	switch (action.type) {
		case PropertiesActionTypes.ADD_PROPERTIES:
			return _.unionBy<Property>([...state, ...action.props], prop => prop.id);
		case PropertiesActionTypes.REFRESH_PROPERTIES:
			return action.props;
		default:
			return state;
	}
};

const propertiesStorageAtom = atomWithStorage<Property[]>('properties', []);

export const propertiesAtom = atom(
	get => get(propertiesStorageAtom),
	(get, set, action: PropertiesAction) => {
		const currentState = get(propertiesStorageAtom);
		const newState = propertiesReducer(currentState, action);
		set(propertiesStorageAtom, newState);
	},
);
