import { memo } from 'react';
import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ApiInterceptor from './interceptors/interceptors.ts';
import { MessageProvider } from './context/message.context.tsx';
import './App.scss';
import { APIProvider } from '@vis.gl/react-google-maps';
import { googleMapsAPIKey } from './config/config.ts';
import AppRoutes from './routes.tsx';

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<HashRouter>
				<APIProvider apiKey={googleMapsAPIKey}>
					<MessageProvider>
						<ApiInterceptor />
						<AppRoutes />
					</MessageProvider>
				</APIProvider>
			</HashRouter>
		</QueryClientProvider>
	);
}

export default memo(App);
