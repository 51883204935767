import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/home.tsx';
import DetailsPage from './pages/details/details.tsx';
import ReservationDetails from './pages/reservation-details/reservation-details.tsx';
import Payment from './pages/payment/payment.tsx';
import Walkthrough from './pages/walkthrough/walkthrough.tsx';

const AppRoutes = memo(function () {
	return (
		<Routes>
			<Route path='/' Component={HomePage} />
			<Route path='/details/:propId' Component={DetailsPage} />
			<Route
				path='/reservation-details/:propId'
				Component={ReservationDetails}
			/>
			<Route path='/payment/:propId' Component={Payment} />
			<Route path='/walkthrough/:propId' Component={Walkthrough} />
		</Routes>
	);
});

export default AppRoutes;
