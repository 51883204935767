import axios, { AxiosResponse } from 'axios';
import {
	GetPropertyListDto,
	PropertiesRes,
	Property,
} from '../models/property.model.ts';
import { ResponseType } from '../interceptors/interceptors.ts';
import { PropertyFiltersModel } from '../models/property-filters.model.ts';
import { getDefaultStore } from 'jotai';
import { propertiesFilterValuesAtom } from '../atoms/properties-filter-values.atom.ts';
import {
	PropertiesActionTypes,
	propertiesAtom
} from '../atoms/properties.atom.ts';

export const getPropertyList = async (
	model: GetPropertyListDto,
): Promise<PropertiesRes> => {
	const res = (await axios.post('api/Properties', model)) as AxiosResponse<
		ResponseType<PropertiesRes>
	>;
	const type =
		model.page === 1
			? PropertiesActionTypes.REFRESH_PROPERTIES
			: PropertiesActionTypes.ADD_PROPERTIES;
	const store = getDefaultStore();
	store.set(propertiesAtom, {
		type,
		props: res.data.data.properties.data,
	});
	store.set(
		propertiesFilterValuesAtom,
		(prev: PropertyFiltersModel | null) =>
			({
				...prev,
				filters: res.data.data.updated_filters,
			}) as PropertyFiltersModel | null,
	);
	return res.data.data as PropertiesRes;
};

export const getPropertyByID = async (propId: string): Promise<Property> => {
	const res = (await axios.get(
		`${import.meta.env.VITE_BASE_URL}api/Property/${propId}`,
	)) as AxiosResponse<ResponseType<Property>>;
	const store = getDefaultStore();
	const props = store.get(propertiesAtom);
	if (!props.some(prop => prop.id === res.data.data.id)) {
		store.set(propertiesAtom, {
			type: PropertiesActionTypes.ADD_PROPERTIES,
			props: [res.data.data],
		});
	}
	return res.data.data;
};

export const getPropertyFilterValues =
	async (): Promise<PropertyFiltersModel> => {
		const res = (await axios.get('api/PropertyFilters')) as AxiosResponse<
			ResponseType<any>
		>;
		return res.data.data as PropertyFiltersModel;
	};
