import { memo, useEffect, useRef, useState } from 'react';
import ActionSheet from '../action-sheet/action-sheet.tsx';
import UnitFilter from '../unit-filter/unit-filter.tsx';
import UnitSort from '../unit-sort/unit-sort.tsx';
import UnitLocation from '../unit-location/unit-location.tsx';
import UnitType from '../unit-type/unit-type.tsx';
import {
	FilterActionsType,
	propertyFilterAtom,
} from '../../atoms/properties-filter.atom.ts';
import { useAtom } from 'jotai/index';
import _ from 'lodash';
import {
	PropertiesActionTypes,
	propertiesAtom,
} from '../../atoms/properties.atom.ts';

const SearchBar = memo(function () {
	const [sortOpen, setOpenSort] = useState(false);
	const [filterOpen, setOpenFilter] = useState(false);
	const [locationOpen, setOpenLocation] = useState(false);
	const [unitTypeOpen, setUnitTypeLocation] = useState(false);
	const [filter, filterDispatcher] = useAtom(propertyFilterAtom);
	const propertiesDispatch = useAtom(propertiesAtom)[1];
	const [searchText, setSearchText] = useState(undefined);
	const searchTextRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (searchText !== undefined) {
			const timeoutId = setTimeout(() => {
				propertiesDispatch({
					type: PropertiesActionTypes.REFRESH_PROPERTIES,
					props: [],
				});
				filterDispatcher({
					type: FilterActionsType.FILTER_CHANGE,
					payload: { text: searchText },
				});
			}, 300);
			return () => clearTimeout(timeoutId);
		}
	}, [searchText]);

	const toggleSort = () => {
		setOpenSort(!sortOpen);
	};

	const openSort = () => {
		setOpenSort(true);
	};

	const closeSort = () => {
		setOpenSort(false);
	};

	const toggleFilter = () => {
		setOpenFilter(!filterOpen);
	};

	const openFilter = () => {
		setOpenFilter(true);
	};

	const closeFilter = () => {
		setOpenFilter(false);
	};

	const toggleLocation = () => {
		setOpenLocation(!locationOpen);
	};

	const openLocation = () => {
		setOpenLocation(true);
	};

	const closeLocation = () => {
		setOpenLocation(false);
	};

	const toggleUnitType = () => {
		setUnitTypeLocation(!unitTypeOpen);
	};

	const openUnitType = () => {
		setUnitTypeLocation(true);
	};

	const closeUnitType = () => {
		setUnitTypeLocation(false);
	};

	const handleSearch = (e: any) => {
		setSearchText(e.target.value);
	};

	const resetFilters = () => {
		searchTextRef.current!.value = '';
		propertiesDispatch({
			type: PropertiesActionTypes.REFRESH_PROPERTIES,
			props: [],
		});
		filterDispatcher({ type: FilterActionsType.RESET_FILTERS });
	};

	const isFilterApplied = () => {
		return (
			isSorted() ||
			!_.isEmpty(filter.filters) &&
			(filter.filters.text ||
				filter.filters.kitchens?.length ||
				filter.filters.bathrooms?.length ||
				filter.filters.guest_rooms?.length ||
				filter.filters.livingrooms?.length ||
				filter.filters.rooms?.length ||
				filter.filters.cities?.length ||
				filter.filters.districts?.length ||
				filter.filters.price ||
				filter.filters.property_types)
		);
	};

	const isFiltered = () => {
		return (
			!_.isEmpty(filter.filters) &&
			(filter.filters.kitchens?.length ||
				filter.filters.bathrooms?.length ||
				filter.filters.guest_rooms?.length ||
				filter.filters.livingrooms?.length ||
				filter.filters.rooms?.length)
		);
	};

	const isLocationFiltered = () => {
		return (
			!_.isEmpty(filter.filters) &&
			(filter.filters.cities?.length || filter.filters.districts?.length)
		);
	};

	const isTypeFiltered = () => {
		return !_.isEmpty(filter.filters) && filter.filters.property_types;
	};

	const isSorted = () => {
		return !_.isEmpty(filter.sort);
	}

	return (
		<div className='search'>
			<div className='search-bar'>
				<i className='icon-search text-grey-200 me-10'></i>
				<input
					ref={searchTextRef}
					type='text'
					className='search-bar__input text-primary'
					placeholder='ابحث هنا'
					onKeyUp={handleSearch}
				/>
				{isFilterApplied() && (
					<button className='btn-link' onClick={resetFilters}>
						<i className='icon-close text-primary'></i>
					</button>
				)}
			</div>
			<div className='filters-btns'>
				<button
					className={`feature2 d-flex align-items-center ${isSorted() ? 'active' : ''}`}
					onClick={toggleSort}>
					<i className='icon-sort-two  fs-15 text-primary p-4 '></i>
					<p className='fs-15 text-primary'>ترتيب</p>
				</button>
				<button
					className={`feature2 d-flex align-items-center ${isFiltered() ? 'active' : ''}`}
					onClick={toggleFilter}>
					<i className='icon-equalizer  fs-15 text-primary p-4'></i>
					<p className='fs-15 text-primary'> فلترة</p>
				</button>
				<button
					className={`feature2 d-flex align-items-center ${isLocationFiltered() ? 'active' : ''}`}
					onClick={toggleLocation}>
					<i className='icon-world  fs-15 text-primary p-4'></i>
					<p className='fs-15 text-primary'> الموقع</p>
				</button>
				<button
					className={`feature2 d-flex align-items-center ${isTypeFiltered() ? 'active' : ''}`}
					onClick={toggleUnitType}>
					<i className='icon-building-one  fs-15 text-primary p-4'></i>
					<p className='fs-15 text-primary'> نوع العقار</p>
				</button>
			</div>

			<ActionSheet open={sortOpen} onClose={closeSort} onOpen={openSort}>
				<UnitSort onCancel={closeSort} />
			</ActionSheet>
			<ActionSheet open={filterOpen} onClose={closeFilter} onOpen={openFilter}>
				<UnitFilter onCancel={closeFilter} />
			</ActionSheet>
			<ActionSheet
				open={locationOpen}
				onClose={closeLocation}
				onOpen={openLocation}>
				<UnitLocation onCancel={closeLocation} />
			</ActionSheet>
			<ActionSheet
				open={unitTypeOpen}
				onClose={closeUnitType}
				onOpen={openUnitType}>
				<UnitType onCancel={closeUnitType} />
			</ActionSheet>
		</div>
	);
});

export default SearchBar;
