import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://6eb595ca741ef9970db020e2102ecb5b@o294159.ingest.us.sentry.io/4508202182049792',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [/^https:\/\/aqarx\.ai/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
	!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (
		<React.StrictMode>
			<App />
		</React.StrictMode>
	) : (
		<App />
	),
);
