import { createContext, memo, ReactNode, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const defaultDuration = 3000;
const defaultSeverity: severityType = 'info';

type severityType = 'success' | 'info' | 'warning' | 'error';

type MessageContextType = {
	showMessage: (
		message: string,
		options?: {
			severity?: severityType,
			duration?: number,
		},
	) => void,
};

const MessageContext = createContext<MessageContextType>({
	showMessage: () => {},
});

const MessageProvider = memo(function (props: {
	children: ReactNode | ReactNode[],
}) {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [severity, setSeverity] = useState<severityType>(defaultSeverity);
	const [duration, setDuration] = useState(defaultDuration);

	const showMessage = (
		message: string,
		options?: {
			severity?: severityType,
			duration?: number,
		},
	) => {
		setOpen(true);
		setMessage(message);
		setSeverity(options?.severity || defaultSeverity);
		setDuration(options?.duration || defaultDuration);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<MessageContext.Provider value={{ showMessage }}>
			<Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity={severity}
					variant='filled'
					sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			{props.children}
		</MessageContext.Provider>
	);
});

export { MessageContext, MessageProvider };
