import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

const Walkthrough = memo(function () {
	const navigate = useNavigate();

	return (
		<div className='walkthrough'>
			<div className='header full-width p-16 justify-content-between d-flex align-items-center'>
				<div>
					<button
						className='btn-link d-flex align-items-center text-decoration-none'
						onClick={() => navigate(-1)}>
						<i className='icon-arrow-right fs-30 text-secondary'></i>
					</button>
				</div>
				<div className='d-flex justify-self-center transform-center'>
					<h1 className='text-secondary m-0 fs-22'>جولة افتراضية</h1>
				</div>
				<h4 className='text-primary d-flex m-0 fs-22 logo'>
					AQARX <i className='icon-logo text-primary ms-5 fs-26'></i>
				</h4>
			</div>
			<iframe
				width='100%'
				src='https://my.matterport.com/show?play=1&lang=en-US&m=2dmQCHgxxLd'
				allowFullScreen
				allow='xr-spatial-tracking'></iframe>
		</div>
	);
});

export default Walkthrough;
