import { memo, useEffect, useMemo, useState } from 'react';
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { useAtom } from 'jotai/index';
import { Property } from '../../models/property.model.ts';
import { propertiesAtom } from '../../atoms/properties.atom.ts';
import Map from '../../components/map/map.tsx';
import Dialog from '../../components/dialog/dialog.tsx';
import Popover from '../../components/popover/popover.tsx';

const ReservationDetails = memo(function () {
	const navigate = useNavigate();
	const { propId } = useParams();
	const [params] = useSearchParams();
	const [properties] = useAtom<Property[]>(propertiesAtom);
	const [paymentModal, setPaymentModal] = useState<'success' | 'failed' | null>(
		null,
	);
	const [showInvoice, setShowInvoice] = useState(false);

	const property = useMemo(
		() => properties.find(prop => prop.id === propId),
		[propId, properties],
	);

	useEffect(() => {
		const payment = params.get('payment') as 'success' | 'failed' | null;
		setPaymentModal(payment);
	}, []);

	const acceptPayment = () => {
		setPaymentModal(null);
		setShowInvoice(true);
	};

	return (
		<div className='reservation-details p-8'>
			<div className='header position-fixed full-width p-16 justify-content-between d-flex align-items-center'>
				<div>
					<button
						className='btn-link d-flex align-items-center text-decoration-none'
						onClick={() => navigate(-1)}>
						<i className='icon-arrow-right fs-30 text-secondary'></i>
					</button>
				</div>
				<div className='d-flex justify-self-center transform-center'>
					<h1 className='text-secondary m-0 fs-22'>تفاصيل الحجز</h1>
				</div>
			</div>

			<div className=' p-8 mt-50'>
				<h4 className='text-secondary fs-15'>تفاصيل العقار</h4>
				<div className='sec1 d-flex justify-content-between align-items-center'>
					<div className='img-details'>
						<img
							src={property?.images[0]}
							className='full-width full-height'
							alt='post-image'
						/>
					</div>
					<div className='sec1-details'>
						<h3 className='text-secondary  fs-12'>{property?.title}</h3>
						<div className='post__features  mt-12 mb-5'>
							<div className='post__features__feature'>
								<i className='icon-overall-reduction me-4'></i>
								{property?.size} م2
							</div>
							<div className='post__features__feature'>
								<i className='icon-single-bed me-4'></i>
								{property?.rooms} غرف
							</div>
							<div className='post__features__feature'>
								<i className='icon-shower-head me-4'></i>
								{property?.bathrooms} دورات مياه
							</div>
						</div>
						<div className='post__values mx-12'>
							<div className='post__values__prices'>
								<h4 className='text-secondary'>
									{property?.discount
										? property?.price - property?.price * property?.discount
										: property?.price}
								</h4>
								{property?.discount !== 0 && (
									<h4 className='text-red line-through'>{property?.price}</h4>
								)}
								<p className='text-secondary fs-12'>ريال</p>
							</div>
						</div>
					</div>
				</div>

				<div>
					<div>
						<h4 className='text-secondary fs-15'>تفاصل الموقع</h4>
					</div>
				</div>
				<div className='location p-10'>
					{property?.location_coords && (
						<Map
							lat={Number(property?.location_coords?.split(',')[0])}
							lng={Number(property?.location_coords?.split(',')[0])}
						/>
					)}
					<p className='text-secondary fs-12 text-bold'>
						مدينة {property?.city.name}، حي {property?.district.name}، شارع{' '}
						{property?.street_name}
					</p>
				</div>

				<Popover text='العربون هو مبلغ مالي يدفعه المستأجر كتأكيد جدية الحجز. يتم استخدام العربون لضمان تأكيد الحجز وحجز الوحدة السكنية المختارة لكم. في حالة تأكيد الحجز واستكمال الإجراءات، يُخصم مبلغ العربون من الإجمالي المستحق للحجز. يتم تطبيق سياسة الإلغاء على مبلغ العربون وفقًا للشروط المحددة في سياسة الحجز.'>
					<div className='d-flex  align-items-center mt-20 mb-10'>
						<h4 className='text-secondary fs-15  m-0'>العربون</h4>
						<i className='info icon-info fs-18 p-6 me-2 '></i>
					</div>
				</Popover>
				<div className='sec2 p-8'>
					<p className=' arbon fs-15 '>
						يُطلب دفع عربون بقيمة 1000 ريال لتأكيد الحجز. العربون يضمن جدية
						الحجز وسيُخصم من الإجمالي المستحق للحجز عند استكمال العملية.
					</p>
				</div>

				<div>
					<div>
						<h4 className='text-secondary fs-20 mb-2 '>طريقةالدفع</h4>
						<h5 className=' sec3 fs-15 m-2'>اختر طريقة الدفع المفضلة لديك</h5>
					</div>

					<div className=' sec4 d-flex justify-content-between align-items-center p-8 mt-10'>
						<div className='d-flex justify-content-between align-items-center p-8 '>
							<div>
								<form>
									<input
										className='fs-30 w-15 h-15'
										type='radio'
										name='payment'
										value=''
										checked
									/>
								</form>
							</div>
							<div>
								<h4 className='head1 fs-15 mt-10 mb-2'>
									ميسر, ماستركارد, فيزا
								</h4>
								<h5 className=' head2 fs-12 mt-2'>
									ادفع بالطريقة اللي تناسبك، بدون فوائد أو رسوم خفية!
								</h5>
							</div>
						</div>

						<div className='d-flex'>
							<img
								src='/images/mada.png'
								className='w-20 object-fit-contain'
								alt='post-image'
							/>
							<img src='/images/visa.png' className=' ' alt='post-image' />
							<img
								src='/images/mastercard.png'
								className=' '
								alt='post-image'
							/>
						</div>
					</div>
				</div>

				<div className='mt-20'>
					<Link
						className='button-pay full-width text-bold text-decoration-none d-flex justify-content-center align-items-center text-decoration-none'
						to={`/payment/${propId}`}>
						الدفع الآن 1000 ريال
					</Link>
				</div>
			</div>

			<Dialog open={paymentModal === 'success'}>
				<div className='payment-modal'>
					<img src='/images/success.png' alt='success' />
					<h3 className='text-primary'>تم دفع العربون بنجاح</h3>
					<p>
						تم استلام العربون بنجاح، وسيتم خصمه من المبلغ الإجمالي المستحق للحجز
						عند استكمال الإجراءات. ستتلقى تأكيدًا رسميًا عبر البريد الإلكتروني
						مع تفاصيل إضافية حول حجزك في غضون دقائق قليلة.
					</p>
					<div className='sec1 d-flex'>
						<div className='img-details'>
							<img
								src={property?.images[0]}
								className='full-width full-height'
								alt='post-image'
							/>
						</div>
						<div className='sec1-details d-flex justify-content-center flex-column'>
							<h3 className='text-secondary text-start m-0 fs-12'>
								{property?.title}
							</h3>
							<div className='post__values'>
								<div className='post__values__prices'>
									<h4 className='text-secondary'>
										{property?.discount
											? property?.price - property?.price * property?.discount
											: property?.price}
									</h4>
									{property?.discount !== 0 && (
										<h4 className='text-red line-through'>{property?.price}</h4>
									)}
									<p className='text-secondary fs-12 m-0'>ريال</p>
								</div>
							</div>
						</div>
					</div>

					<hr className='my-10' />

					<div className='d-flex flex-column gap-10'>
						<div className='d-flex justify-content-between'>
							<h4 className='text-grey-400 my-5'>سعر العقار</h4>
							<h4 className='text-secondary my-5'>
								{property?.discount
									? property?.price - property?.price * property?.discount
									: property?.price}{' '}
								ريال
							</h4>
						</div>
						<div className='d-flex justify-content-between'>
							<h4 className='text-grey-400 my-5'>العربون</h4>
							<h4 className='text-secondary my-5'>1000 ريال</h4>
						</div>
					</div>

					<hr className='my-10 border-dashed' />

					<div className='d-flex justify-content-between'>
						<h4 className='text-grey-400 my-5'>المتبقي</h4>
						<h4 className='text-primary my-5'>
							{property?.discount
								? property?.price - property?.price * property?.discount - 1000
								: property?.price && property?.price - 1000}{' '}
							ريال
						</h4>
					</div>

					<p className='text-grey-400 text-center'>
						في حالة تأكيد الحجز واستكمال الإجراءات، يُخصم مبلغ العربون من
						الإجمالي المستحق للحجز. يتم تطبيق سياسة الإلغاء على مبلغ العربون
						وفقًا للشروط المحددة في سياسة الحجز.
					</p>

					<button
						className='button-pay full-width text-bold text-decoration-none d-flex justify-content-center align-items-center text-decoration-none'
						onClick={acceptPayment}>
						موافق
					</button>
				</div>
			</Dialog>

			<Dialog open={paymentModal === 'failed'}>
				<div className='payment-modal'>
					<img src='/images/error.png' alt='success' />
					<h3 className='text-red mb-0'>حدث خطأ أثناء الدفع</h3>
					<p>
						يرجى ملاحظة أن عملية الدفع لم تكتمل بنجاح. لضمان استمرارية الخدمة أو
						استلام المنتج، يُرجى التحقق من تفاصيل الدفع والمحاولة مرة أخرى. إذا
						واجهت أي مشكلات، يُرجى التواصل مع خدمة العملاء.
					</p>
					<Link
						to={`/payment/${propId}`}
						className='button-pay full-width text-bold text-decoration-none d-flex justify-content-center align-items-center text-decoration-none'>
						أعد المحاولة
					</Link>
				</div>
			</Dialog>

			<Dialog open={showInvoice} onClose={() => setShowInvoice(false)}>
				<div className='payment-modal invoice'>
					<div className='sec1 d-flex'>
						<div className='img-details'>
							<img
								src={property?.images[0]}
								className='full-width full-height'
								alt='post-image'
							/>
						</div>
						<div className='sec1-details d-flex justify-content-center flex-column'>
							<h3 className='text-secondary text-start m-0 fs-12'>
								{property?.title}
							</h3>
							<div className='post__values'>
								<div className='post__values__prices'>
									<h4 className='text-secondary'>
										{property?.discount
											? property?.price - property?.price * property?.discount
											: property?.price}
									</h4>
									{property?.discount !== 0 && (
										<h4 className='text-red line-through'>{property?.price}</h4>
									)}
									<p className='text-secondary fs-12 m-0'>ريال</p>
								</div>
							</div>
						</div>
					</div>

					<hr className='my-16' />

					<div className='d-flex flex-wrap gap-y-16'>
						<div className='half-width text-start'>
							<h6 className='text-grey-400 mt-0 mb-4'>سعر الوحدة</h6>
							<h4 className='text-secondary m-0'>
								{property?.discount
									? property?.price - property?.price * property?.discount
									: property?.price}{' '}
								ريال
							</h4>
						</div>
						<div className='half-width text-start'>
							<h6 className='text-grey-400 mt-0 mb-4'>طريقة البيع</h6>
							<h4 className='text-secondary m-0'>الحجز عن طريق العربون</h4>
						</div>
						<div className='half-width text-start'>
							<h6 className='text-grey-400 mt-0 mb-4'>عدد الغرف</h6>
							<h4 className='text-secondary m-0'>{property?.rooms} غرف</h4>
						</div>
						<div className='half-width text-start'>
							<h6 className='text-grey-400 mt-0 mb-4'>عدد دورات المياه</h6>
							<h4 className='text-secondary m-0'>
								{property?.bathrooms} دورات المياه
							</h4>
						</div>
						<div className='half-width text-start'>
							<h6 className='text-grey-400 mt-0 mb-4'>المساحة</h6>
							<h4 className='text-secondary m-0'>{property?.size} م2</h4>
						</div>
					</div>

					<hr className='my-10' />

					<div className='d-flex flex-column gap-10'>
						<div className='d-flex justify-content-between'>
							<h4 className='text-grey-400 my-5'>سعر العقار</h4>
							<h4 className='text-secondary my-5'>
								{property?.discount
									? property?.price - property?.price * property?.discount
									: property?.price}{' '}
								ريال
							</h4>
						</div>
						<div className='d-flex justify-content-between'>
							<h4 className='text-grey-400 my-5'>العربون</h4>
							<h4 className='text-secondary my-5'>1000 ريال</h4>
						</div>
					</div>

					<hr className='my-10 border-dashed' />

					<div className='d-flex justify-content-between mb-40'>
						<h4 className='text-grey-400 my-5'>المتبقي</h4>
						<h4 className='text-primary my-5'>
							{property?.discount
								? property?.price - property?.price * property?.discount - 1000
								: property?.price && property?.price - 1000}{' '}
							ريال
						</h4>
					</div>

					<button
						className='button-pay full-width text-bold text-decoration-none d-flex justify-content-center align-items-center text-decoration-none'
						onClick={() => window.print()}>
						طباعة الفاتورة
					</button>
				</div>
			</Dialog>
		</div>
	);
});

export default ReservationDetails;
