import { memo, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { useAtom } from 'jotai';
import { Property } from '../../models/property.model.ts';
import { propertiesAtom } from '../../atoms/properties.atom.ts';
import Rate from '../../components/rate/rate.tsx';
import Map from '../../components/map/map.tsx';
import Popover from '../../components/popover/popover.tsx';
import { CircularProgress } from '@mui/material';
import { getPropertyByID } from '../../services/property.service.ts';

const DetailsPage = memo(function () {
	const navigate = useNavigate();
	const { propId } = useParams();
	const [properties] = useAtom<Property[]>(propertiesAtom);

	const property = useMemo(() => {
		const prop = properties.find(prop => prop.id === propId);
		if (!prop) {
			propId && getPropertyByID(propId);
		}
		return prop;
	}, [propId, properties]);

	useEffect(() => {
		window.scrollTo({top: 0, behavior: 'instant'});
	}, []);

	const sliderSettings: Settings = {
		arrows: false,
		dots: true,
		className: 'property-slider',
		customPaging: index => (
			<img
				className='h-52 w-52 object-fit-cover'
				src={property?.images[index]}
				alt={`image-${property?.images[index]}`}
			/>
		),
	};
	
	if (!property) {
		return (
			<div className='text-center mt-150'>
				<CircularProgress />
			</div>
		);
	}

	return (
		<div className='details-page'>
			<div className='header position-fixed full-width p-16 justify-content-between d-flex align-items-center'>
				<div>
					<button
						className='btn-link d-flex align-items-center text-decoration-none'
						onClick={() => navigate(-1)}>
						<i className='icon-arrow-right fs-30 text-secondary'></i>
					</button>
				</div>
				<div className='d-flex justify-self-center transform-center'>
					<h1 className='text-secondary m-0 fs-22'>تفاصيل العقار</h1>
				</div>
			</div>

			<div className='content px-16'>
				<div className='m-0'>
					<div className='slider-container'>
						<div className='slider-header'>
							<h5 className='text-secondary m-0'>{property?.developer.name}</h5>
							<img
								src={property?.developer.logo}
								className='object-fit-contain h-22'
								alt='company'
							/>
						</div>
						<Slider {...sliderSettings}>
							{property?.images.map((image, index) => (
								<img
									src={image}
									key={index}
									className='h-337 object-fit-cover full-width slider-image'
									alt={`image-${index}`}
								/>
							))}
						</Slider>
					</div>
					<div className='d-flex justify-content-between py-12'>
						<h3 className='text-secondary fs-18 m-0'>{property?.title}</h3>
						<button className='btn-link d-flex align-items-center'>
							<i className='icon-fi_share  fs-23 text-primary'></i>
						</button>
					</div>
					<div className='post__values d-inline-block full-width'>
						<div className='d-flex justify-content-between align-items-center full-width'>
							<div className='post__values__prices'>
								<h4 className='text-secondary'>
									{property?.discount
										? property?.price - property?.price * property?.discount
										: property?.price}
								</h4>
								{property?.discount !== 0 && (
									<h4 className='text-red line-through'>{property?.price}</h4>
								)}
								<p className='text-secondary fs-12'>ريال</p>
							</div>
							{property?.discount !== 0 && (
								<p className='m-0 text-secondary fw-500'>
									خصم {property?.discount}%
								</p>
							)}
						</div>
						<div className='post__values__impressions'>
							<p className='post__values__impressions__rate m-0'>
								{property?.ratings.reduce((avg, value, _, { length }) => {
									return avg + value.rating / length;
								}, 0)}
								<i className='icon-star'></i>
							</p>
							<p className='post__values__impressions__watches m-0'>
								{property?.views} <i className='icon-preview-open'></i>
							</p>
						</div>
					</div>
				</div>
				<div>
					<p className='text-grey-300 fs-13 m-0'>{property?.description}</p>
				</div>

				<div>
					<div>
						<h4 className='text-secondary m-0'>تفاصل الموقع</h4>
					</div>
				</div>

				<div className='location p-10 '>
					{property?.location_coords && (
						<Map
							lat={Number(property?.location_coords?.split(',')[0])}
							lng={Number(property?.location_coords?.split(',')[0])}
						/>
					)}
					<p className='text-secondary fs-12 text-bold'>
						مدينة {property?.city.name}، حي {property?.district.name}، شارع{' '}
						{property?.street_name}
					</p>
				</div>

				<div>
					<h4 className='text-secondary fs-15 mt-0'>تفاصيل العقار</h4>
					<div className='d-flex justify-content-between gap-16 flex-wrap'>
						<div className='feature d-flex align-items-center'>
							<i className='icon-overall-reduction  fs-15 text-primary p-8'></i>
							<p className='fs-15 text-primary'>{property?.size} 2م</p>
						</div>
						<div className='feature d-flex align-items-center'>
							<i className='icon-single-bed  fs-15 text-primary p-8'></i>
							<p className='fs-15 text-primary'>{property?.rooms} غرف</p>
						</div>
						<div className='feature d-flex align-items-center'>
							<i className='icon-shower-head  fs-15 text-primary p-8'></i>
							<p className='fs-15 text-primary'>
								{property?.bathrooms} دورات مياه
							</p>
						</div>
						<div className='feature d-flex align-items-center mb-20'>
							<i className='icon-chef-hat  fs-15 text-primary p-8'></i>
							<p className='fs-15 text-primary'>{property?.kitchens} مطبخ </p>
						</div>
						<div className='feature d-flex align-items-center'>
							<i className='icon-group  fs-15 text-primary p-8'></i>
							<p className='fs-15 text-primary'>{property?.parkings} صالة</p>
						</div>
						<div className='feature d-flex align-items-center'>
							<i className='icon-graphic-stitching-three  fs-15 text-primary p-8'></i>
							<p className='fs-15 text-primary'>
								{property?.living_rooms} مجلس
							</p>
						</div>
					</div>
					<div>
						<h4 className='text-secondary fs-15'>تفاصيل الوحدة</h4>
						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-database-code fs-15 text-primary p-8 pt-2'></i>
								<h5 className='unit-details fs-15 m-0'>كود البناء</h5>
							</div>
							<h4 className='m-0'>{property?.building_code}</h4>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center mb-2'>
								<i className=' unit-details icon-building-one fs-15 text-primary p-8 pt-3'></i>
								<h5 className='unit-details fs-15 m-1'>حالة البناء</h5>
							</div>
							<h4 className='m-0'>
								{property?.property_status === 'complete' ? 'مكتمل' : 'جديد'}
							</h4>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-electric-wave fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>عداد الكهرباء</h5>
							</div>
							<h4 className='m-0'>
								{property?.is_separate_electricity ? 'منفصل' : 'غير منفصل'}
							</h4>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-water fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>عداد ماء</h5>
							</div>
							<h4 className='m-0'>
								{property?.is_separate_water ? 'منفصل' : 'غير منفصل'}
							</h4>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-database-code fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>رقم الصك</h5>
							</div>
							<h4 className='m-0'>120988</h4>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-percentage fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>نسبة الوحدة من الصك</h5>
							</div>
							<h4 className='m-0'>34%</h4>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-parking fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>عدد الموقف</h5>
							</div>
							<h4 className='m-0'>{property?.parkings}</h4>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-parking fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>الامتيازات</h5>
							</div>
							<Popover
								text='موقع مميز قريب من الخدمات الأساسية مثل المدارس والمستشفيات ومراكز التسوق.
تصاميم معمارية حديثة وحدائق ومرافق ترفيهية داخل المشروع السكني.
خدمات أمان وحراسة على مدار الساعة.'>
								<div className=' d-flex justify-content-between align-items-center '>
									<h4 className='m-0'>{property?.privileges.join(', ')}</h4>
									<i className='unit-details icon-info fs-15 ps-8 fs-18'></i>
								</div>
							</Popover>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-parking fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>خدمات ما بعد البيع</h5>
							</div>
							<Popover
								text='خدمات الصيانة الدورية للوحدات السكنية والمرافق المشتركة.
توفير خدمة عملاء مخصصة لحل مشاكل السكان والإجابة عن استفساراتهم.
برامج إدارة العقارات التي تشمل التأجير وصيانة الممتلكات نيابة عن المالك.'>
								<div className=' d-flex justify-content-between align-items-center '>
									<h4 className='m-0'>متوفرة</h4>
									<i className='unit-details icon-info fs-15 ps-8 fs-18'></i>
								</div>
							</Popover>
						</div>

						<div className='d-flex justify-content-between'>
							<div className=' d-flex justify-content-between align-items-center'>
								<i className=' unit-details icon-parking fs-15 text-primary p-8'></i>
								<h5 className='unit-details fs-15 m-0'>الضمانات</h5>
							</div>
							<Popover
								text='ضمانات على هيكل البناء تصل إلى 10 سنوات أو أكثر.
ضمانات على الأجهزة والمعدات المنزلية المثبتة لمدة سنتين إلى خمس سنوات.
ضمانات على التركيبات الكهربائية والسباكة لفترة محددة من الزمن.'>
								<div className=' d-flex justify-content-between align-items-center'>
									<h4 className='m-0'>متوفرة</h4>
									<i className='unit-details icon-info fs-15 ps-8 fs-18'></i>
								</div>
							</Popover>
						</div>
					</div>

					<div>
						<h4 className='text-secondary fs-15'>جولةافتراضية</h4>
						<Link
							className='show d-flex justify-content-between align-items-center p-5 text-decoration-none'
							to={`/walkthrough/${propId}`}>
							<div className='d-flex justify-content-between align-items-center '>
								<i className='icon-content-360deg fs-20 text-primary p-6'></i>
								<p className='text-primary fs-15'>عرض 360 درجة</p>
							</div>
							<button className='btn-link'>
								<i className='icon-arrow-left fs-20 text-primary'></i>
							</button>
						</Link>
					</div>

					<div>
						<div className='d-flex justify-content-between align-items-center'>
							<h4 className='text-secondary fs-15'>التعليقات و التقيمات</h4>
							<h5 className='text-primary fs-13'>عرض المزيد</h5>
						</div>
						{property?.ratings.map((rating, index) => (
							<div className='comment p-20 mt-20' key={index}>
								<div className='d-flex justify-content-between align-items-center'>
									<h5 className='text-secondary fs-15 mb-1 mt-2'>
										{rating.name}
									</h5>
									<Rate rating={rating.rating} />
								</div>
								<p className='text-grey-300 fs-13'>{rating.comment}</p>
							</div>
						))}
					</div>

					<div className='d-flex justify-content-between align-items-center text-center mt-20 mb-30'>
						<Link
							className='button-save text-bold d-flex justify-content-center align-items-center text-decoration-none'
							to={`/reservation-details/${propId}`}>
							ابدأ الحجز
						</Link>
						<button className='button-developer text-bold'>
							التواصل مع المطور
						</button>
					</div>
				</div>
			</div>
		</div>
	);
});

export default DetailsPage;
